var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"600px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.event)?_c('v-card',{staticClass:"custom__dialog"},[_c('v-card-title',{staticClass:"dialog__header"},[_c('h3',{staticClass:"dialog__title"},[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-spacer'),_c('v-btn',{staticClass:"close__dialog",attrs:{"fab":"","small":"","depressed":""},on:{"click":_vm.clear_and_close}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',{staticClass:"dialog__body"},[_c('v-row',{staticClass:"justify-center align-center",attrs:{"no-gutters":""}},[_c('label',[_vm._v(_vm._s(_vm.event_date))]),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('label',[_vm._v(_vm._s(_vm.event_time))]),(_vm.event && _vm.event.properties.alarm)?_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v(" mdi-bell ")]):_vm._e(),_c('v-spacer'),(_vm.can_edit_event)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"outlined":"","color":"#b3b7c3","icon":""},on:{"click":function($event){return _vm.emitEvents('edit-event', _vm.event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-edit")])],1)]}}],null,false,3781701311)},[_c('span',[_vm._v("Edit Event")])]):_vm._e(),(_vm.can_delete_event)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"outlined":"","color":"#b3b7c3","icon":""},on:{"click":function($event){return _vm.emitEvents('delete-event', _vm.event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-remove")])],1)]}}],null,false,3871115456)},[_c('span',[_vm._v("Delete Event")])]):_vm._e(),(_vm.can_add_participants)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"outlined":"","color":"#b3b7c3","icon":""},on:{"click":function($event){return _vm.emitEvents('add-participant', _vm.event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-multiple-plus-outline")])],1)]}}],null,false,2629959252)},[_c('span',[_vm._v("Manage Participants")])]):_vm._e()],1),(_vm.event)?_c('v-row',{attrs:{"no-gutters":""}},[(_vm.event.event_type)?_c('v-col',{staticClass:"pa-4",attrs:{"md":"12"}},[_c('v-avatar',{attrs:{"size":"20","color":_vm.event.event_type.properties.color}}),_c('span',{staticClass:"font-weight-medium title"},[_vm._v(" "+_vm._s(_vm.event.event_type.name))])],1):_vm._e(),_c('v-col',{staticClass:"pa-4",attrs:{"md":"4"}},[_c('Avatars',{attrs:{"items":_vm.event.users,"has-add-member":_vm.can_add_participants,"count":7},on:{"add-member":function($event){return _vm.emitEvents('add-participant', _vm.event)}}})],1),_c('v-col',{staticClass:"pa-4",attrs:{"md":"12"}},[(_vm.event.description)?_c('div',{staticClass:"responsive",domProps:{"innerHTML":_vm._s(_vm.event.description)}}):_vm._e(),(_vm.event.properties.link)?_c('div',{staticClass:"responsive"},[_vm._v(" Link : "),_c('v-btn',{staticClass:"link-btn ml-1",attrs:{"dark":"","depressed":"","color":"primary","href":_vm.event.properties.link,"target":"_blank"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-account-tie-voice-outline")]),_vm._v(" Open Link ")],1)],1):_vm._e()]),_c('v-col',[_c('v-divider'),_c('Comment',{attrs:{"id":_vm.event.id,"type":"event"}})],1)],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }