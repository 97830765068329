import _cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { global_filters } from '@/global_utils/global_filters'

export default {
    mixins: [global_filters],
    props: {
        event: Object,
        dense: { type: Boolean, default: false },
        avatarCount: { type: Number, default: 2 }
    },
    computed: {
        is_creator() {
            return this.event.properties.creator === this.loggeduser.id
        },
        permission() {
            if (!this.loggeduser.is_admin)
                return this.$_permissions.get('calendars_own')

            return this.$_permissions.get('calendars')
        },
        loggeduser() {
            return this.$store.getters.user
        },
        datetimedisplay() {
            let s = moment(this.event.start)
            let e = moment(this.event.end)
            if (this.event.all_day) {
                return `${s.format('MMM D YYYY')}`
            }
            let x = moment(this.event.start).format('MMM D YYYY')
            let y = moment(this.event.end).format('MMM D YYYY')

            let start_time = moment(this.event.start).format('HH:mm A')
            if (x === y) {
                let time = start_time === '00:00 AM' ? false : start_time
                return time ? `${x} - ${time}` : x
            } else {
                return s.format('MMM D YYYY H:mm A')
            }
        },
        more_user() {
            if (this.event.users.length < 4) {
                return ''
            }
            return `+${this.event.users.length - 3} more invited`
        },
        first_three_participants() {
            if (this.event.participants.length < 4) {
                return this.event.participants
            }
            return _cloneDeep(this.event.participants).slice(0, 3)
        },
        can_delete() {
            if (this.event.properties.creator === this.loggeduser.id) return true
            if (this.loggeduser.is_admin) return true

            return this.permission && this.permission.delete
        },
        can_edit() {
            if (this.event.properties.creator === this.loggeduser.id) return true
            if (this.loggeduser.is_admin) return true

            return this.permission && this.permission.update
        },
        eventColor() {
            if (!this.event.event_type) return 'blue'
            return this.event.event_type.properties.color
        }
    },
    methods: {
        navigate_project() {
            this.$router.push({
                name: 'hq',
                params: { id: this.event.project.id, type: this.event.project.type }
            })
        }
    }
}