import { list_functionality } from '@/services/list-functionality/list-functionality'
import moment from 'moment'
import { calendar_utils } from '@/services/calendar/calendar_utils'
//Components
import VueTable from '@/common/VueTable/VueTable.vue'
import Actions from '@/common/VueTable/Actions.vue'
import TableHeader from '@/common/TableHeader.vue'
import DatePicker from '@/common/DatePicker.vue'
import CalendarDialog from '@/modules/CalendarV2/components/CalendarDialog/CalendarDialog.vue'
import EventDialogV2 from '@/modules/CalendarV2/components/EventDialog.vue'
import EventTypeDialog from '@/modules/CalendarV2/components/EventTypeDialog/EventTypeDialog.vue'
import EventDetailDialog from '@/modules/CalendarV2/components/EventDetailDialog/EventDetailDialog.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import ConfirmDialog from '@/common/ConfirmDialog.vue'
import AddParticipantDialog from '@/modules/CalendarV2/components/AddParticipantDialog/AddParticipantDialog.vue'
import EventCard from '@/modules/CalendarV2/components/EventCard/EventCard.vue'

export default {
    name: 'Alarm',
    mixins: [list_functionality, calendar_utils],
    components: {
        VueTable,
        Actions,
        TableHeader,
        DatePicker,
        CalendarDialog,
        DeleteDialog,
        ConfirmDialog,
        EventCard,
        EventDialogV2,
        EventTypeDialog,
        EventDetailDialog,
        AddParticipantDialog
    },

    data: () => ({
        paths: [{
                text: 'Dashboard',
                disabled: false,
                route: { name: 'default-content' }
            },
            { text: 'Timers', disabled: true, route: null },
            { text: 'Alarm', disabled: true, route: null }
        ],
        sortList: [
            { title: 'Sort by Client' },
            { title: 'Sort by Task' },
            { title: 'Sort by Services' },
            { title: 'Sort by Time' },
            { title: 'Sort by Date' }
        ],
        headers: [
            { text: 'Events', sortable: true, align: 'left', value: 'title' },
            { text: 'Participants', sortable: false, align: 'left' },
            { text: 'Type', sortable: false, align: 'left' },
            { text: 'Date', sortable: true, align: 'left', value: 'start' },
            { text: 'Time', value: 'time_end', sortable: false, align: 'left' },
            {
                text: 'Action',
                sortable: false,
                align: 'center',
                value: 'action',
                width: 160
            }
        ],
        timer_tab: 'alarm',
        currentTab: 'alarm',
        filter_date: moment().format('YYYY-MM-DD'),
        today: moment().format('YYYY-MM-DD')
    }),

    mounted() {
        this.$event.$emit('path-change', this.paths)
        this.fill_table_via_url(this.api)
    },

    computed: {
        loggeduser() {
            return this.$store.getters.user
        },
        dateSelected() {
            return moment(this.filter_date).format('YYYY-MM-DD')
        },
        api() {
            return `/api/events?alarm=true&page=${this.pagination.current}`
        }
    },

    methods: {
        load_more() {
            this.load_more_via_url(this.api)
        },

        handleChangeTab(event) {
            if (this.timer_tab === 'task-timers')
                this.$router.push({ name: 'taskTimer' })
            if (this.timer_tab === 'global-timers')
                this.$router.push({ name: 'globalTimer' })
        },

        is_event_owner(item) {
            return item.properties.creator === this.loggeduser.id
        },

        handleAction() {
            this.appSnackbar('Coming soon, working on it!')
        },
        minimize() {
            this.$router.push({ name: 'default-content' })
        }
    }
}