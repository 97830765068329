<template>
  <CustomDialog
    :button2-text="save_button_label"
    :main-btn-disabled="!can_save"
    :max-width="800"
    :open.sync="open"
    :title="dialog_title"
    @button1="cancel"
    @button2="save"
    ref="dialog"
  >
    <template slot="content">
      <v-card flat>
        <v-card-text>
          <v-row class="my-0">
            <v-col cols="4">
              <v-select
                :items="recurrences"
                hide-details
                disabled
                label="Event Recurrence *"
                v-model="event.recurrence"
              />
            </v-col>
            <v-col cols="4">
              <v-checkbox
                dense
                hide-details
                label="Alarm Event ?"
                v-model="event.alarm"
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>
                      If set, It will notify participants 15 minutes before the
                      start of event.
                    </span>
                  </v-tooltip>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                dense
                hide-details
                label="All Day Event ?"
                v-model="event.allDay"
              />
            </v-col>
            <v-col :cols="event.allDay ? 12 : 6">
              <vck-datetime-picker
                :min-date="today"
                :only-date="true"
                auto-close
                color="#3b589e"
                format="YYYY-MM-DD"
                formatted="YYYY-MM-DD"
                label="Start Date *"
                no-button
                no-button-now
                no-keyboard
                output-format="YYYY-MM-DD"
                v-model="event.starting.date"
              />
            </v-col>
            <v-col cols="6" v-if="!event.allDay">
              <vck-datetime-picker
                :disabled="start_time_is_disabled"
                :minute-interval="5"
                color="#3b589e"
                format="hh:mm a"
                formatted="hh:mm a"
                label="Start Time *"
                no-button
                no-button-now
                no-keyboard
                only-time
                output-format="HH:mm:ss"
                overlay
                v-model="event.starting.time"
              />
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col :cols="event.allDay ? 12 : 6">
              <vck-datetime-picker
                :disabled="end_date_is_disabled"
                :min-date="event.starting.date"
                auto-close
                color="#3b589e"
                format="YYYY-MM-DD"
                formatted="YYYY-MM-DD"
                label="End Date *"
                no-button
                no-button-now
                no-keyboard
                only-date
                output-format="YYYY-MM-DD"
                overlay
                v-model="event.ending.date"
              />
            </v-col>
            <v-col cols="6" v-if="!event.allDay">
              <vck-datetime-picker
                :disabled="end_time_is_disabled"
                :minute-interval="5"
                color="#3b589e"
                format="hh:mm a"
                formatted="hh:mm a"
                label="End Time *"
                no-button
                no-button-now
                no-keyboard
                only-time
                output-format="HH:mm:ss"
                overlay
                v-model="event.ending.time"
              />
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="6">
              <v-text-field
                counter="40"
                label="Event Title *"
                v-model.trim="event.title"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                :items="categories"
                :menu-props="{ top: true, offsetY: true }"
                @click:append-outer="$emit('open-custom-event-type')"
                append-outer-icon="mdi-plus-circle-outline"
                item-text="name"
                item-value="id"
                label="Event Type *"
                v-model="event.event_type"
              >
                <template v-slot:selection="data">
                  <v-chip class="my-1 border-0" outlined v-bind="data.attrs">
                    <v-icon :color="data.item.color" left>
                      mdi-checkbox-blank-circle
                    </v-icon>
                    <span>{{ data.item.name }}</span>
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <span>
                    <v-icon :color="item.color" left>mdi-circle</v-icon>
                    {{ item.name }}
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="6">
              <v-text-field
                clear-icon="mdi-close-circle-outline"
                clearable
                label="Event Link"
                placeholder="https://"
                v-model.trim="event.link"
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.normalize_title
                          }}</v-list-item-title>
                          <v-list-item-subtitle>
                            <span>{{ item.project_type | ucwords }}</span>
                            <span v-if="item.props.business_name">
                              | {{ item.props.business_name | ucwords }}
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="projects"
                :menu-props="{ top: true, offsetY: true }"
                :multiple="false"
                @click:clear="event.project = null"
                clear-icon="mdi-close-circle-outline"
                clearable
                hide-details
                item-text="title"
                item-value="id"
                label="Project|Campaign (Optional)"
                v-model="event.project"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title> No Project found! </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <span>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title" />
                        <v-list-item-subtitle>
                          <span>{{ item.type | ucwords }}</span>
                          <span v-if="item.props.business_name">
                            | {{ item.props.business_name | ucwords }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                :items="members.items"
                chips
                clear-icon="mdi-close-circle-outline"
                clearable
                dense
                hide-details
                hide-selected
                item-text="fullname"
                item-value="id"
                label="Event Participants"
                multiple
                v-model="event.participants"
              >
                <template v-slot:selection="data">
                  <v-chip
                    @click:close="removeParticipant(data.item)"
                    class="my-1"
                    close
                    outlined
                    v-bind="data.attrs"
                  >
                    <v-avatar left>
                      <v-img
                        :lazy-src="settings.loader"
                        :src="data.item.image_url"
                      />
                    </v-avatar>
                    {{ data.item.first_name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar>
                      <img :src="data.item.image_url" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.fullname" />
                      <v-list-item-subtitle v-html="data.item.job_title" />
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <Editor v-model="event.description" min-height="100"> </Editor>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import { mapGetters } from 'vuex'
import Editor from '@/common/Editor/Editor.vue'
import _cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

export default {
  name: 'EventDialog',
  components: {
    CustomDialog,
    Editor
  },
  props: {
    dialog: Boolean,
    isEditDialog: Boolean,
    eventToEdit: Object,
    eventPayload: Object
  },
  data: () => ({
    open: false,
    event: {
      title: null,
      description: null,
      event_type: null,
      project: null,
      link: null,
      backgroundColor: '#2196f3',
      allDay: true,
      alarm: false,
      participants: [],
      starting: {
        date: null,
        time: '00:00:00'
      },
      ending: {
        date: null,
        time: '00:00:00'
      },
      recurrence: 'none',
      timezone: 'UTC'
    },
    default: {
      title: null,
      description: null,
      event_type: null,
      project: null,
      link: null,
      backgroundColor: '#2196f3',
      allDay: true,
      alarm: false,
      participants: [],
      starting: {
        date: null,
        time: '00:00:00'
      },
      ending: {
        date: null,
        time: '00:00:00'
      },
      recurrence: 'none',
      timezone: 'UTC'
    },
    members: {
      original: [],
      items: []
    },
    project_select: false,
    today: moment().format('YYYY-MM-DD')
  }),
  watch: {
    eventToEdit: {
      handler(val) {
        if (val && this.isEditDialog) {
          this.mapEvent(val)
        }
      },
      immediate: true,
      deep: true
    },
    dialog(new_val) {
      this.open = new_val
      this.event.timezone = this.user_timezone
      if (new_val && !this.isEditDialog) {
        this.event.title = null
        this.event.description = null
        this.event.event_type = null
        this.event.project = null
        this.event.link = null
        this.event.backgroundColor = '#2196f3'
        this.event.allDay = true
        this.event.alarm = false
        this.event.participants = []
        this.event.starting = {
          date: null,
          time: '00:00:00'
        }
        this.event.ending = {
          date: null,
          time: '00:00:00'
        }
        this.event.recurrence = 'none'
        this.event.timezone = 'UTC'
      }
    },
    open(new_val) {
      this.$emit('update:dialog', new_val)
    },
    eventPayload: {
      handler(payload) {
        if (payload) {
          this.event.event_type = payload.extendedProps
            ? payload.extendedProps.eventtypes_id
            : null
          if (payload && payload.view.type === 'dayGridMonth') {
            /* "date": "2020-09-01T16:00:00.000Z", "dateStr": "2020-09-02", "allDay": true,*/ //click
            /* "start": "2020-09-07T16:00:00.000Z", "end": "2020-09-09T16:00:00.000Z", "startStr": "2020-09-08", "endStr": "2020-09-10", "allDay": true,*/ //drag_select
            if (payload.hasOwnProperty('dateStr')) {
              this.event.starting.date = payload.dateStr
              this.event.starting.time = '00:00:00'
              this.event.ending.date = payload.dateStr
              this.event.ending.time = '00:00:00'
            }
            if (payload.hasOwnProperty('startStr')) {
              this.event.starting.date = payload.startStr
              this.event.starting.time = '00:00:00'
            }
            if (payload.hasOwnProperty('endStr')) {
              this.event.ending.date = payload.endStr
              this.event.ending.time = '00:00:00'
            }
            this.event.allDay = payload.allDay
          } else if (
            payload &&
            (payload.view.type === 'timeGridWeek' ||
              payload.view.type === 'timeGridDay')
          ) {
            /* "date": "2020-09-06T22:30:00.000Z", "dateStr": "2020-09-07T06:30:00+08:00", "allDay": false,*/ //click
            /* "start": "2020-09-05T22:00:00.000Z", "end": "2020-09-06T00:30:00.000Z", "startStr": "2020-09-06T06:00:00+08:00", "endStr": "2020-09-06T08:30:00+08:00", "allDay": false*/ //drag select
            if (payload.hasOwnProperty('dateStr')) {
              let date = moment.parseZone(payload.dateStr)
              this.event.starting.date = date.clone().format('YYYY-MM-DD')
              this.event.starting.time = date.clone().format('HH:mm:00')
              this.event.ending.date = date.clone().format('YYYY-MM-DD')
              this.event.ending.time = '00:00:00'
            }
            if (payload.hasOwnProperty('startStr')) {
              let date1 = moment.parseZone(payload.startStr)
              this.event.starting.date = date1.clone().format('YYYY-MM-DD')
              this.event.starting.time = date1.clone().format('HH:mm:00')
            }
            if (payload.hasOwnProperty('endStr')) {
              let date2 = moment.parseZone(payload.endStr)
              this.event.ending.date = date2.clone().format('YYYY-MM-DD')
              this.event.ending.time = date2.clone().format('HH:mm:00')
            }
            this.event.allDay = payload.allDay
          }
        }
      },
      deep: true,
      immediate: true
    },
    users: {
      handler(val) {
        this.members.original = _cloneDeep(val)
        this.members.items = _cloneDeep(val)
      },
      immediate: true,
      deep: true
    },
    'event.event_type': {
      handler(val) {
        if (val) {
          let index = this.categories.findIndex((ev) => ev.id === val)
          if (~index) {
            this.event.backgroundColor = this.categories[index].color
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user', 'users']),
    ...mapGetters('calendar', ['categories', 'projects']),
    dialog_title() {
      return this.isEditDialog ? 'Edit Event' : 'Add Event'
    },
    save_button_label() {
      return this.isEditDialog ? 'Update event' : 'Save Event'
    },
    available() {
      return this.users.length > 0 ? _cloneDeep(this.users) : []
    },
    end_date_is_disabled() {
      if (!this.event.allDay) {
        if (!this.event.starting.date || !this.event.starting.time) return true
      } else {
        if (!this.event.starting.date) return true
      }
      return false
    },
    end_time_is_disabled() {
      return (
        !this.event.starting.date ||
        !this.event.starting.time ||
        !this.event.ending.date
      )
    },
    start_time_is_disabled() {
      return !this.event.starting.date
    },
    end_time_min_value() {
      return moment(`${this.event.starting.date} ${this.event.starting.time} `)
    },
    recurrences() {
      return [
        { text: 'None', value: 'none' },
        // {text: "Daily", value: "daily"}, //@todo to be optimize in backend,temporary disabled
        { text: 'Weekly', value: 'weekly' },
        { text: 'Monthly', value: 'monthly' }
      ]
    },
    user_timezone() {
      return moment.tz.guess() || 'UTC'
    },
    can_save() {
      return this.validEvent(false)
    }
  },
  methods: {
    cancel() {},
    validEvent(showError) {
      if (!this.event.starting || !this.event.starting.date) {
        if (showError) this.appSnackbar('Start date and time required')
        return false
      }
      if (!this.event.ending || !this.event.ending.date) {
        if (showError) this.appSnackbar('End date and time required')
        return false
      }
      if (
        moment(`${this.event.starting.date} ${this.event.starting.time}`) >
        moment(`${this.event.ending.date} ${this.event.ending.time}`)
      ) {
        if (showError)
          this.appSnackbar(
            'End date and time should not be less than the start date and time '
          )
        return false
      }
      if (!this.event.title) {
        if (showError) this.appSnackbar('Title is required')
        return false
      }
      if (!this.event.event_type) {
        if (showError) this.appSnackbar('Event type is required')
        return false
      }
      if (this.event.link && !this.validateUrl(this.event.link)) {
        if (showError) this.appSnackbar('Event link is invalid')
        return false
      }
      return true
    },
    save() {
      if (this.validEvent(true)) {
        if (this.isEditDialog) this.$emit('update', this.getPayload())
        else this.$emit('save', this.getPayload())

        this.event = this.default
      }
    },
    getPayload() {
      let payload = this.event
      let start = moment(
        `${this.event.starting.date} ${this.event.starting.time}`
      )
      let end = moment(`${this.event.ending.date} ${this.event.ending.time}`)
      if (start > end) {
        this.appSnackbar(
          'End date and time should not be less than the start date and time '
        )
        return
      }
      payload.recurrence = 'none'
      payload.timezone = this.user_timezone
      payload.groupId = start.clone().format('YYYYMMDD')
      payload.start = start.format('YYYY-MM-DD HH:mm:00')
      payload.end = end.format('YYYY-MM-DD HH:mm:00')
      payload.project_id = payload.project
      if (this.isEditDialog) payload.id = this.eventToEdit.id
      else payload.id = moment().unix()
      return payload
    },
    removeParticipant(item) {
      let index = this.event.participants.findIndex((user) => user === item.id)
      if (~index) this.event.participants.splice(index, 1)
    },
    mapEvent(event) {
      this.event = {
        title: event.title,
        description: event.extendedProps.description,
        event_type: event.extendedProps.eventtypes_id,
        project: event.extendedProps.project_id,
        link: event.extendedProps.url,
        backgroundColor: event.backgroundColor,
        allDay: event.allDay,
        alarm: event.extendedProps.alarm,
        participants: event.extendedProps.participants.map((item) => {
          return item.id
        }),
        starting: {
          date: moment(event.start).format('YYYY-MM-DD'),
          time: moment(event.start).format('HH:mm:00')
        },
        ending: {
          date: moment(event.end).format('YYYY-MM-DD'),
          time: moment(event.end).format('HH:mm:00')
        },
        recurrence: event.recurrence,
        timezone: event.extendedProps.timezone
      }
    }
  }
}
</script>

<style scoped>
.popup-calendar-labe {
  font-size: 16px;
  color: #223a6b;
  font-weight: 500;
}
.calendar-input-height {
  height: 80px;
}
</style>
