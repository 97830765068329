<template>
  <CustomDialog
    :title="title"
    :button1-text="cancelButtonText"
    :button2-text="confirmButtonText"
    :open.sync="open"  
    @button1="cancel_clicked"
    @button2="confirm_clicked"
  >
    <template v-slot:content>
      <slot name="content">
        <v-row dense class="fill-height" align="center" justify="center">
          <v-col md="12" class="title">
            <v-banner outlined tile>
              <v-avatar slot="icon" color="primary" size="40">
                <v-icon icon="mdi-lock" color="white">
                  mdi-exclamation-thick
                </v-icon>
              </v-avatar>
              <p class="subtitle-1 font-weight-bold primary--text my-0">
                {{ textContent }}
              </p>
            </v-banner>
          </v-col>
        </v-row>
      </slot>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";

export default {
  components: {
    CustomDialog,
  },
  props: {
    title: { type: String, default: "Confirm Action" },
    textContent: { type: String, default: "" },
    cancelButtonText: { type: String, default: "Cancel" },
    confirmButtonText: { type: String, default: "Confirm" },
    openDialog: Boolean,
    value: { type: Boolean, default: false },
  },

  data: () => ({
    open: false,
  }),

  watch: {
    openDialog(val) {
      this.open = val;
    },
    value(val) {
      this.open = val;
    },
    open(val) {
      this.$emit("update:openDialog", val);
      this.$emit("input", val);
    },
  },

  methods: {
    cancel_clicked() {
      this.open = false;
    },
    confirm_clicked() {
      this.$emit("confirm");
    },
    showDialog() {
      this.open = true;
    },
    closeDialog() {
      this.open = false;
    },
  },
};
</script>