<template>
  <CustomDialog
    v-model="dialog"
    title="Manage Participants"
    @button1="clear_and_close"
    @button2="save"
    button2-text="Save"
    max-width="800"
    :main-btn-disabled="disabled"
  >
    <template v-slot:content>
      <v-card dense flat v-if="event">
        <v-row dense align="stretch" justify="space-between" class="flex-wrap">
          <v-col cols="12" md="6" sm="12">
            <v-subheader class="subtitle-2 primary--text">
              Members ({{ members.original.length }})
            </v-subheader>
            <v-list
              v-if="available.length > 0"
              dense
              max-height="400"
              class="overflow-y-auto"
            >
              <v-list-item
                v-for="(user, index) in available"
                :key="index"
                @click="add_to(user, index)"
              >
                <v-list-item-avatar>
                  <v-img :lazy-src="settings.loader" :src="user.image_url" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="primary--text">
                    {{ user.fullname | ucwords }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="secondary--text">
                    {{ user.job_title | ucwords }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-account-arrow-right-outline
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            <Empty min-height="400" v-else headline="No more available"></Empty>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-subheader class="subtitle-2 primary--text">
              Selected ({{ members.selected.length }})
            </v-subheader>
            <v-list
              v-if="members.selected.length > 0"
              dense
              max-height="400"
              class="overflow-y-auto"
            >
              <v-list-item
                v-for="(user, index) in members.selected"
                :key="index"
                :disabled="is_event_creator(user)"
                @click="remove_from(user, index)"
              >
                <v-list-item-avatar>
                  <v-img :lazy-src="settings.loader" :src="user.image_url" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="primary--text">
                    {{ user.fullname | ucwords }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="secondary--text">
                    {{ user.job_title | ucwords }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="primary" v-if="!is_event_creator(user)">
                    mdi-account-arrow-left-outline
                  </v-icon>
                  <v-icon color="error" v-else>
                    mdi-account-cancel-outline
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            <Empty min-height="400" v-else headline="No selected yet"></Empty>
          </v-col>
        </v-row>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
//components
import _cloneDeep from 'lodash/cloneDeep'
import _diffBy from 'lodash/differenceBy'
import { mapActions, mapGetters } from 'vuex'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
  name: 'AddParticipantDialog',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    event: { type: Object }
  },

  data: () => ({
    dialog: false,
    members: {
      items: [],
      original: [],
      selected: []
    }
  }),
  watch: {
    value: {
      handler: function (val) {
        this.dialog = val
      },
      immediate: true
    },
    event: {
      handler: function (val) {
        if (val && val.extendedProps) {
          this.members.selected = _cloneDeep(val.extendedProps.participants)
        } else {
          this.members.selected = []
        }
      },
      deep: true,
      immediate: true
    },
    dialog(val) {
      this.$emit('input', val)
    },
    usersBaseOnViewer: {
      handler: function (val) {
        this.members.original = _cloneDeep(val)
        this.members.items = _cloneDeep(val)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user', 'usersBaseOnViewer']),
    disabled() {
      return this.members.selected.length === 0
    },
    available() {
      return _diffBy(this.members.items, this.members.selected, 'id')
    }
  },
  methods: {
    ...mapActions('calendar', ['manageParticipants']),
    openDialog() {
      this.dialog = true
    },
    is_event_creator(user) {
      return this.event && this.event.extendedProps.creator == user.id
    },
    save() {
      let payload = {
        id: this.event.id,
        participants: this.members.selected.map((user) => user.id)
      }
      this.$emit('save', payload)
    },
    clear_and_close() {
      this.members.selected = []
      this.members.items = this.members.original
      this.dialog = false
    },
    add_to(user, index) {
      this.members.selected.push(user)
      this.members.items.splice(index, 1)
    },
    remove_from(user, index) {
      this.members.items.push(user)
      this.members.selected.splice(index, 1)
    }
  }
}
</script>
<style lang="scss"></style>
