import moment from 'moment'
//components
import TextField from '@/common/BaseComponents/TextField.vue'
import Comment from '@/common/Comment/Comment.vue'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
    name: 'EventDetailDialog',
    components: {
        TextField,
        Comment,
        CustomDialog
    },
    props: {
        event: Object
    },
    data: () => ({
        dialog: false,
        btnloading: false,
        all_comments: [],
        dropdown_actions: [
            { id: 1, text: 'Edit', value: 'edit' },
            { id: 2, text: 'Delete', value: 'delete' }
        ],
        commenter: null,
        hover: false,
        activeComment: null
    }),
    created() {
        this.$event.$on('close-all-dialog', () => {
            this.clear_and_close()
        })
    },
    computed: {
        dialogTitle() {
            return this.event.title
        },
        event_date() {
            return moment(this.event.start).format('MMMM D YYYY')
        },
        event_time() {
            return moment(this.event.start).format('h:mm A')
        },
        user() {
            return this.$store.getters.user
        },
        permission() {
            return this.$_permissions.get('message')
        },
        can_edit_event() {
            if (this.user.is_admin) return true
            return this.event.properties.creator === this.user.id
        },
        can_delete_event() {
            if (this.user.is_admin) return true
            return this.event.properties.creator === this.user.id
        },
        can_add_participants() {
            if (this.user.is_admin) return true
            return this.event.properties.creator === this.user.id
        },
        hasActions() {
            return (
                this.can_add_participants ||
                this.can_edit_event ||
                this.can_delete_event
            )
        }
    },
    methods: {
        emitEvents(name, item) {
            this.$event.$emit(name, item)
        },
        openDialog() {
            this.dialog = true
        },
        clear_and_close() {
            this.dialog = false
        }
    }
}