<template>
  <v-menu ref="menu" :close-on-content-click="false" v-model="menu" transition="scale-transition"
          offset-y min-width="290px" bottom
  >
    <template v-slot:activator="{ on }">
      <v-text-field slot="activator" :value="picker_date" v-bind="$attrs" :filled="filled" hide-details="auto" color="#657186"
                    readonly v-on="on" :width="minWidth" :disabled="disabled" clearable :placeholder="placeholder"
                    clear-icon="mdi-close-circle-outline" :label="label" :dense="dense"
                    @click:clear="clear_date" class="cursor-pointer"
      />
    </template>
    <v-date-picker clearable color="#657186" v-model="picker_date" :max="max" :min="min" no-title scrollable
                   @click:date="dateClick" show-current type="date"
    >
      <v-spacer />
      <v-btn text outlined v-if="hasButtons" color="#657186" @click="cancel">Cancel</v-btn>
      <v-btn text outlined v-if="hasButtons" color="#657186" @click="save">OK</v-btn>
      <v-btn text outlined v-if="hasButtons" color="#657186" @click="clear">Clear</v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: 'DatePicker',
  inheritAttrs: false,
  props: {
    label: {type: String, default: null},
    placeholder: {type: String, default: 'yyyy-mm-dd'},
    value: {type: String, default: null},
    min: {type: String, default: null},
    max: {type: String, default: null},
    minWidth: {type: String, default: '290px'},
    hasButtons: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
    dense: {type: Boolean, default: false},
    filled: {type: Boolean, default: false}
  },

  data: () => ({
    menu: false,
    picker_date: null
  }),

  watch: {
    value(val) {
      if (!val || val.toLowerCase() === 'invalid date')
        this.picker_date = null
      else
        this.picker_date = val
    }
  },

  methods: {
    save() {
      this.$emit('input', this.picker_date)
      this.cancel()
    },
    cancel() {
      this.menu = false
    },
    clear() {
      this.picker_date = null
    },
    clear_date() {
      this.$emit('input', null)
    },
    dateClick() {
      if (!this.hasButtons) {
        this.save()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.date-picker {
  
}
</style>